<template>
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Status</th>
          <th v-if="!showClient">
            Job
          </th>
          <th v-if="showClient">
            Client
          </th>
          <th>Size</th>
          <th>Objects</th>
          <th>Started on</th>
          <th>Ended on</th>
          <th>Duration</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(taskSession, taskSessionIndex) in orderedTaskSessions">
          <tr
            :key="`${taskSessionIndex}`"
          >
            <td>
              <feather-icon
                :icon="taskSession.showDetails === true ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                class="cursor-pointer float-left"
                @click="toggleDetails(taskSession)"
              />
              <JobStatusIcon :status="taskSession.calculatedStatus" />
            </td>
            <td v-if="!showClient">
              <b-link :to="{ name: 'veeam-detail.job-detail', params: { jobName: taskSession.group } }">
                {{ taskSession.group }}
              </b-link>
            </td>
            <td v-if="showClient">
              <b-link :to="{ name: 'veeam-detail.client-detail', params: { clientName: taskSession.objectName } }">
                {{ taskSession.objectName }}
              </b-link>
            </td>
            <td>{{ taskSession.totalSize | prettyBytes({binary: true}) }}</td>
            <td>{{ taskSession.processedObjects }} / {{ taskSession.totalObjects }}</td>
            <td>{{ taskSession.start | formatDateTimeTz(timezone) }}</td>
            <td>{{ taskSession.end | formatDateTimeTz(timezone) }}</td>
            <td>{{ taskSession.duration | formatClrTimeSpan }}</td>
          </tr>
          <tr
            v-if="taskSession.showDetails"
            :key="`${taskSessionIndex}_detail`"
          >
            <td
              colspan="7"
            >
              <div class="row">
                <div class="col">
                  <dl>
                    <dt>Avg. speed</dt>
                    <dd>
                      <template v-if="taskSession.avgSpeed">
                        {{ taskSession.avgSpeed | prettyBytes({binary: true}) }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </dd>

                    <dt>Read avg. size</dt>
                    <dd>
                      <template v-if="taskSession.readAvgSize">
                        {{ taskSession.readAvgSize | prettyBytes({binary: true}) }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </dd>

                    <dt>Processed objects</dt>
                    <dd>
                      <template v-if="taskSession.processedObjects">
                        {{ taskSession.processedObjects }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </dd>

                    <dt>Total objects</dt>
                    <dd>
                      <template v-if="taskSession.totalObjects">
                        {{ taskSession.totalObjects }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </dd>

                    <dt>Read size</dt>
                    <dd>
                      <template v-if="taskSession.readSize">
                        {{ taskSession.readSize | prettyBytes({binary: true}) }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </dd>

                    <dt>Processed size</dt>
                    <dd>
                      <template v-if="taskSession.processedSize">
                        {{ taskSession.processedSize | prettyBytes({binary: true}) }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </dd>

                    <dt>Total size</dt>
                    <dd>
                      <template v-if="taskSession.totalSize">
                        {{ taskSession.totalSize | prettyBytes({binary: true}) }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </dd>

                    <dt>Stored size</dt>
                    <dd>
                      <template v-if="taskSession.storedSize">
                        {{ taskSession.storedSize | prettyBytes({binary: true}) }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </dd>

                    <dt>Total used size</dt>
                    <dd>
                      <template v-if="taskSession.totalUsedSize">
                        {{ taskSession.totalUsedSize | prettyBytes({binary: true}) }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </dd>
                  </dl>
                </div>
                <div class="col">
                  <dt>Reason</dt>
                  <dd>{{ taskSession.reason || '-' }}</dd>
                </div>
                <div class="col">
                  <JobLogTable
                    :job-log="taskSession.log"
                    :timezone="timezone"
                  />
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

import { BLink } from 'bootstrap-vue'
import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'
import JobLogTable from './JobLogTable.vue'

export default {
  components: {
    BLink,
    JobLogTable,
    JobStatusIcon,
  },
  props: {
    taskSessions: {
      type: Array,
      default: () => [],
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
    showClient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    orderedTaskSessions() {
      if (this.taskSessions) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.taskSessions.sort((a, b) => (a.start > b.start ? -1 : 1))
      }

      return []
    },
  },
  methods: {
    toggleDetails(taskSession) {
      // eslint-disable-next-line no-param-reassign
      this.$set(taskSession, 'showDetails', !taskSession.showDetails)
    },
  },
}

</script>
