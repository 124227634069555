<template>
  <table class="table table-sm">
    <thead>
      <tr>
        <th>Status</th>
        <th>Title</th>
        <th>Start</th>
        <th>End</th>
        <th>Duration</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="log in jobLog"
        :key="log.id"
      >
        <td>
          <feather-icon
            :class="getLogStatusVariant(log.status)"
            icon="CircleIcon"
          />
        </td>
        <td>{{ log.title }}</td>
        <td>{{ log.start | formatDateTimeTz(timezone) }}</td>
        <td>{{ log.end | formatDateTimeTz(timezone) }}</td>
        <td>{{ log.duration | formatClrTimeSpan }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    jobLog: {
      type: Array,
      default: () => [],
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
  },
  methods: {
    getLogStatusVariant(status) {
      switch (status) {
        case 'EWarning':
          return 'warning'
        case 'ENone':
        case 'ESucceeded':
          return 'success'
        default:
          return 'danger'
      }
    },
  },
}
</script>

<style scoped>
  .success {
    fill: var(--success);
    color: var(--success);
  }
  .warning {
    fill: var(--warning);
    color: var(--warning);
  }
  .danger {
    fill: var(--danger);
    color: var(--danger);
  }
</style>
