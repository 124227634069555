<template>
  <div>
    <b-card title="Job Session Details">
      <b-row>
        <b-col
          cols="12"
          sm="4"
        >

          <dl class="row">
            <dt class="col-sm-4">
              Job
            </dt>
            <dd class="col-sm-8">
              <b-link :to="{ name: 'veeam-detail.job-detail', params: { jobName: jobSession.jobName } }">
                {{ jobSession.jobName }}
              </b-link>
            </dd>

            <dt class="col-md-4">
              Started on
            </dt>
            <dd class="col-md-8">
              {{ jobSession.start | formatDateTimeTz(asup.timezone) }}
            </dd>

            <dt class="col-md-4">
              Ended on
            </dt>
            <dd class="col-md-8">
              {{ jobSession.end | formatDateTimeTz(asup.timezone) }}
            </dd>

            <dt class="col-md-4">
              Duration
            </dt>
            <dd class="col-md-8">
              {{ jobSession.duration | formatClrTimeSpan }}
            </dd>

            <dt class="col-md-4">
              Progress
            </dt>
            <dd class="col-md-8">
              {{ jobSession.progress }}%
            </dd>

            <dt class="col-md-4">
              Is Active Full
            </dt>
            <dd class="col-md-8">
              {{ jobSession.isActiveFull }}
            </dd>

            <dt class="col-md-4">
              Is Full
            </dt>
            <dd class="col-md-8">
              {{ jobSession.isFull }}
            </dd>
          </dl>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <dl class="row">
            <dt class="col-md-4">
              Status
            </dt>
            <dd class="col-md-8">
              <JobStatusIcon :status="jobSession.calculatedStatus" />
            </dd>
            <dt class="col-md-4">
              State
            </dt>
            <dd class="col-md-8">
              {{ jobSession.state }}
            </dd>

            <dt class="col-md-4">
              Result
            </dt>
            <dd class="col-md-8">
              {{ jobSession.result }}
            </dd>

            <dt class="col-md-4">
              Reason
            </dt>
            <dd class="col-md-8">
              {{ jobSession.reason }}
            </dd>
          </dl>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <dl class="row">
            <dt class="col-md-4">
              Avg. speed
            </dt>
            <dd class="col-md-8">
              <template v-if="jobSession.avgSpeed">
                {{ jobSession.avgSpeed | prettyBytes({binary: true}) }}
              </template>
              <template v-else>
                -
              </template>
            </dd>

            <dt class="col-md-4">
              Read avg. size
            </dt>
            <dd class="col-md-8">
              <template v-if="jobSession.readAvgSize">
                {{ jobSession.readAvgSize | prettyBytes({binary: true}) }}
              </template>
              <template v-else>
                -
              </template>
            </dd>

            <dt class="col-md-4">
              Processed objects
            </dt>
            <dd class="col-md-8">
              <template v-if="jobSession.processedObjects">
                {{ jobSession.processedObjects }}
              </template>
              <template v-else>
                -
              </template>
            </dd>

            <dt class="col-md-4">
              Total objects
            </dt>
            <dd class="col-md-8">
              <template v-if="jobSession.totalObjects">
                {{ jobSession.totalObjects }}
              </template>
              <template v-else>
                -
              </template>
            </dd>

            <dt class="col-md-4">
              Read size
            </dt>
            <dd class="col-md-8">
              <template v-if="jobSession.readSize">
                {{ jobSession.readSize | prettyBytes({binary: true}) }}
              </template>
              <template v-else>
                -
              </template>
            </dd>

            <dt class="col-md-4">
              Processed size
            </dt>
            <dd class="col-md-8">
              <template v-if="jobSession.processedSize">
                {{ jobSession.processedSize | prettyBytes({binary: true}) }}
              </template>
              <template v-else>
                -
              </template>
            </dd>

            <dt class="col-md-4">
              Total size
            </dt>
            <dd class="col-md-8">
              <template v-if="jobSession.totalSize">
                {{ jobSession.totalSize | prettyBytes({binary: true}) }}
              </template>
              <template v-else>
                -
              </template>
            </dd>

            <dt class="col-md-4">
              Stored size
            </dt>
            <dd class="col-md-8">
              <template v-if="jobSession.storedSize">
                {{ jobSession.storedSize | prettyBytes({binary: true}) }}
              </template>
              <template v-else>
                -
              </template>
            </dd>

            <dt class="col-md-4">
              Total used size
            </dt>
            <dd class="col-md-8">
              <template v-if="jobSession.totalUsedSize">
                {{ jobSession.totalUsedSize | prettyBytes({binary: true}) }}
              </template>
              <template v-else>
                -
              </template>
            </dd>
          </dl>
        </b-col>
      </b-row>
    </b-card>

    <b-card :title="`Task sessions (${orderedTaskSessions.length})`">
      <TaskSessionTable
        :task-sessions="orderedTaskSessions"
        :timezone="asup.timezone"
        :show-client="true"
      />
    </b-card>

    <b-card title="Logs">
      <JobLogTable
        :job-log="jobSession.logs"
        :timezone="asup.timezone"
      />
    </b-card>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, VBPopover, BLink,
} from 'bootstrap-vue'
import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'
import VeeamService from '@/service/veeam.service'
import JobLogTable from './components/JobLogTable.vue'
import TaskSessionTable from './components/TaskSessionTable.vue'
import moment from '@/libs/moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    JobStatusIcon,
    BLink,
    JobLogTable,
    TaskSessionTable,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      jobSession: {},
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    jobName() {
      return this.$route.params.jobName
    },
    jobSessionId() {
      return this.$route.params.jobSessionId
    },
    orderedTaskSessions() {
      if (!this.jobSession || !this.jobSession.taskSessions) {
        return []
      }
      return this.jobSession.taskSessions
        .concat()
        .sort((a, b) => (moment(a.start) > moment(b.start) ? -1 : 1))
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      VeeamService.getJobSessionAsync(this.asup.id, this.jobName, this.jobSessionId, { disableTenantFilter: true })
        .then(result => {
          this.jobSession = result
        })
    },
    toggleDetails(data) {
      // eslint-disable-next-line no-param-reassign
      this.$set(data, 'showDetails', !data.showDetails)
    },
  },
}
</script>
